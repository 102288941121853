import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type Store from '@ember-data/store'
import type { FeatureService } from '@blakeelearning/features'
import { graphql } from 're-client/graphql'
import type ApolloService from 're-client/services/apollo'

const ReadBooksQueryDocument = graphql(/* GraphQL */ `
  query ReadBooks($first: Int!, $after: String) {
    student {
      id
      readBooks(first: $first, after: $after) {
        edges {
          node {
            code
            coverUrl
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`)

interface Book {
  code: string
  coverUrl: string
}

interface BookshelfModel {
  books: Book[] | undefined
  bookPages: AsyncGenerator<{ books: Book[]; hasNextPage: boolean }> | undefined
}

export default class BookshelfRoute extends Route<BookshelfModel> {
  @service
  declare apollo: ApolloService

  @service
  declare store: Store

  @service
  declare features: FeatureService

  override async model() {
    const bookshelf = await this.store.findRecord('bookshelf', 'bookshelf')

    let bookPages
    let books

    if (this.features.isEnabled('fable_books')) {
      bookPages = this.bookPages()
    } else {
      books = bookshelf.bookCovers.map((bookCoverData) => ({
        code: bookCoverData.id,
        coverUrl: bookCoverData.coverUrl,
      }))
    }

    return { books, bookPages }
  }

  async *bookPages(first = 10) {
    // First page
    let result = await this.apollo.query({
      query: ReadBooksQueryDocument,
      variables: {
        first,
      },
      fetchPolicy: 'network-only',
    })

    while (result.data.student?.readBooks.pageInfo.endCursor) {
      const {
        edges,
        pageInfo: { endCursor, hasNextPage },
      } = result.data.student.readBooks

      const books =
        edges?.map((edge) => edge?.node).filter((book) => !!book) ?? []
      yield { books, hasNextPage }

      if (!hasNextPage) break

      // Next page
      result = await this.apollo.query({
        query: ReadBooksQueryDocument,
        variables: {
          first,
          after: endCursor,
        },
        fetchPolicy: 'network-only',
      })
    }
  }
}
