/* eslint-disable ember/no-test-import-export */
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type DebugModeService from 're-client/services/debug-mode'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import { debugAction } from 're-client/utils/debug'
import { v4 as uuidV4 } from 'uuid'
import type ErrorHandlerService from 're-client/services/error-handler'
import { useMutation } from 're-client/resources/mutation'
import { graphql } from 're-client/graphql'

export const saveSpellingLessonPlacementTestResultMutationDocument = graphql(
  /* GraphQL */ `
    mutation SaveSpellingLessonPlacementTestResult(
      $input: SpellingLessonPlacementTestResultCreateInput!
    ) {
      spellingLessonPlacementTestResultCreate(input: $input) {
        student {
          id
          ...StudentProgressFragment
          ...AssignmentTask
        }
      }
    }
  `,
)
export default class PlacementTestController extends Controller {
  @service
  declare debugMode: DebugModeService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare router: RouterService

  @service
  declare errorHandler: ErrorHandlerService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  saveSpellingLessonPlacementTestResultMutation = useMutation(
    this,
    saveSpellingLessonPlacementTestResultMutationDocument,
  )

  @action
  async placementTestComplete(lessonId: number, uuid?: string) {
    try {
      const data =
        await this.saveSpellingLessonPlacementTestResultMutation.current.mutate(
          {
            variables: {
              input: {
                attemptUuid: uuid ?? uuidV4(),
                lesson: lessonId,
                skippedTest: false,
              },
            },
          },
        )

      if (!data)
        throw new Error(
          '[SaveSpellingLessonPlacementTestResult] no data returned from mutation',
        )

      this.interactive.callInteractionMethod('nextable')
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveSpellingLessonPlacementTestResult] mutation failed',
        error,
      )
    }
  }

  @action
  next() {
    this.router.transitionTo(
      'spelling.map',
      this.studentProgress.spellingCurrentMap,
    )
  }

  @action
  @debugAction({
    lessonId: {
      type: 'number',
      value: '1',
    },
  })
  async completePlacementTest({ lessonId }: { lessonId: number }) {
    await this.placementTestComplete(lessonId)
    this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'spelling/placement-test': PlacementTestController
  }
}
