import RESTAdapter from '@ember-data/adapter/rest'
import type { Snapshot } from '@ember-data/store'
import type ModelRegistry from 'ember-data/types/registries/model'
import { decamelize, underscore } from '@ember/string'

export default class CaperAdapter extends RESTAdapter {
  override buildURL<K extends keyof ModelRegistry>(
    modelName?: K,
    id?: unknown,
    snapshot?: Snapshot<K>,
    requestType?: string,
    query?: object,
  ): string {
    const url = super.buildURL(modelName, id, snapshot, requestType, query)
    return `${url}.json`
  }

  override pathForType(modelName: keyof ModelRegistry) {
    const path = decamelize(modelName)
    return underscore(path)
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    caper: CaperAdapter
  }
}
