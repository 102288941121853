import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import type StorylandsLessonActivityRoute from 're-client/routes/storylands/lesson/activity'
import type ActivitySidebarService from 're-client/services/activity-sidebar'
import type AssignmentsService from 're-client/services/assignments'
import type DebugModeService from 're-client/services/debug-mode'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { StorylandsQuizData } from 're-client/services/location-tracker'
import type LocationTrackerService from 're-client/services/location-tracker'
import type LogoutService from 're-client/services/logout'
import type StorylandsService from 're-client/services/storylands'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import { debugAction } from 're-client/utils/debug'
import { calculateMap } from 're-client/utils/progress-tools'
import type { ModelFor } from 're-client/utils/route-model'
import { v4 as uuidV4 } from 'uuid'

interface QuizResults {
  correct: number
  total: number
  incorrect_answers: Record<string, string | number>
  correct_answers: (string | number)[]
}

export const saveClinkerCastleLessonActivityResultMutationDocument = graphql(
  /* GraphQL */ `
    mutation SaveClinkerCastleLessonActivityResult(
      $input: ClinkerCastleLessonActivityResultCreateInput!
    ) {
      clinkerCastleLessonActivityResultCreate(input: $input) {
        clinkerCastleLessonActivityResult {
          reward {
            eggs
          }
        }
        student {
          id
          ...StudentProgressFragment
          ...AssignmentTask
        }
      }
    }
  `,
)

export const saveClinkerCastleLessonQuizResultMutationDocument = graphql(
  /* GraphQL */ `
    mutation SaveClinkerCastleLessonQuizResult(
      $input: ClinkerCastleLessonQuizResultCreateInput!
    ) {
      clinkerCastleLessonQuizResultCreate(input: $input) {
        clinkerCastleLessonQuizResult {
          reward {
            eggs
            prize
          }
        }
        student {
          id
          ...StudentProgressFragment
          ...AssignmentTask
        }
      }
    }
  `,
)

export default class StorylandsLessonActivityController extends Controller {
  @service
  declare router: RouterService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare locationTracker: LocationTrackerService

  @service
  declare assignments: AssignmentsService

  @service
  declare debugMode: DebugModeService

  @service
  declare storylands: StorylandsService

  @service
  declare user: UserService

  @service
  declare activitySidebar: ActivitySidebarService

  @service
  declare logout: LogoutService

  @service
  declare errorHandler: ErrorHandlerService

  declare model: ModelFor<StorylandsLessonActivityRoute>

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  saveClinkerCastleLessonActivityResultMutation = useMutation(
    this,
    saveClinkerCastleLessonActivityResultMutationDocument,
  )

  saveClinkerCastleLessonQuizResultMutation = useMutation(
    this,
    saveClinkerCastleLessonQuizResultMutationDocument,
  )

  get isAssignmentMode() {
    return this.model?.isAssignmentMode
  }

  get currentPositionTitle() {
    return this.isAssignmentMode
      ? this.lessonId
      : this.studentProgress.storylandsCurrentLesson.toString()
  }

  get currentMap() {
    return this.studentProgress.storylands.currentMap
  }

  get lessonId() {
    return Number(this.model?.lesson.id)
  }

  get activityId() {
    return Number(this.model?.activityId)
  }

  get activityIndex() {
    return this.activityId - 1
  }

  get nextActivity() {
    return this.model?.lesson.nextActivity(this.activityIndex)
  }

  get mapId() {
    return calculateMap(
      this.lessonId,
      config.studentProgress.progress.storylands.lessonsPerMap,
    )
  }

  get shouldLogout() {
    return (
      this.user.student.rosterEnabled &&
      this.isAssignmentMode &&
      !this.assignments.currentTask
    )
  }

  @action
  changeActivity(newActivityID: string | number) {
    if (this.storylands.canPlayLessonActivity(this.lessonId, newActivityID)) {
      this.router.transitionTo('storylands.lesson.activity', newActivityID)
    }
  }

  @action
  next() {
    if (this.shouldLogout) {
      this.logout.doLogout()
      return
    }

    if (this.nextActivity) {
      this.router.transitionTo(
        'storylands.lesson.activity',
        this.nextActivity.id,
      )
      return
    }

    if (this.currentMap > config.studentProgress.progress.storylands.lastMap) {
      this.router.transitionTo('storylands.finished-re-storylands')
    } else {
      this.goToMap()
    }
  }

  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    if (typeof args === 'number') {
      this.user.incrementEggs(args)
    } else {
      this.activitySidebar.open(args.amount)
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/require-await -- caper activity needs to be updated
  async reportQuizState() {
    return this.locationTracker.getStorylandsQuizState()
  }

  @action
  setQuizState(state: StorylandsQuizData) {
    this.locationTracker.setStorylandsQuizState(state)
  }

  @action
  clearQuizState() {
    this.locationTracker.setStorylandsQuizState(null)
  }

  @action
  playBook(bookId: string | number, page: string | number) {
    this.router.transitionTo('reader.book.page', bookId, page)
  }

  @action
  goToHouse() {
    this.router.transitionTo('house')
  }

  @action
  goToChangeRoom() {
    this.router.transitionTo('changing-room')
  }

  @action
  async saveProgress(args?: { uuid: string }) {
    let assignment = null
    try {
      if (
        this.assignments.currentTask?.__typename ===
          'AssignmentTaskClinkerCastle' &&
        this.assignments.canCompleteStorylandsAssignmentTask(
          this.lessonId,
          this.activityId,
        )
      ) {
        assignment = {
          assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
          assignmentUuid: this.assignments.currentTask.assignmentUuid,
        }
      }

      const data =
        await this.saveClinkerCastleLessonActivityResultMutation.current.mutate(
          {
            variables: {
              input: {
                lessonInPrecinct: this.lessonId,
                activityInLesson: this.activityId,
                attemptUuid: args?.uuid ?? uuidV4(),
                assignment,
              },
            },
          },
        )

      if (!data)
        throw new Error(
          '[SaveClinkerCastleLessonActivityResult] No data returned from mutation',
        )

      this.interactive.callInteractionMethod(
        'nextable',
        data.clinkerCastleLessonActivityResultCreate
          .clinkerCastleLessonActivityResult,
      )
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveClinkerCastleLessonActivityResult] mutation failed',
        error,
      )
    }
  }

  @action
  async saveQuizResult(results: QuizResults, uuid?: string) {
    let assignment = null
    try {
      if (
        this.assignments.currentTask?.__typename ===
          'AssignmentTaskClinkerCastle' &&
        this.assignments.canCompleteStorylandsAssignmentTask(
          this.lessonId,
          this.activityId,
        )
      ) {
        assignment = {
          assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
          assignmentUuid: this.assignments.currentTask.assignmentUuid,
        }
      }

      const data =
        await this.saveClinkerCastleLessonQuizResultMutation.current.mutate({
          variables: {
            input: {
              lesson: this.lessonId,
              correctAnswers: results.correct_answers.map((a) => a.toString()),
              incorrectAnswers: Object.fromEntries(
                Object.entries(results.incorrect_answers).map(([k, v]) => [
                  k,
                  v.toString(),
                ]),
              ),
              attemptUuid: uuid ?? uuidV4(),
              assignment,
            },
          },
        })

      if (!data)
        throw new Error(
          '[SaveClinkerCastleLessonQuizResult] No data returned from mutation',
        )

      this.interactive.callInteractionMethod(
        'nextable',
        data.clinkerCastleLessonQuizResultCreate.clinkerCastleLessonQuizResult,
      )
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveClinkerCastleLessonQuizResult] mutation failed',
        error,
      )
    }
  }

  /**
   * Transition to the map for this storylands lesson
   *
   * @instance
   * @memberOf LessonActivityController
   */
  @action
  goToMap() {
    this.router.transitionTo('storylands.map', this.currentMap)
  }

  @action
  @debugAction()
  async completeActivity() {
    await this.saveProgress()
    this.next()
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: 'Pass Quiz', value: 'pass' },
        { label: 'Fail Quiz', value: 'fail' },
      ],
      values: {
        pass: {
          correct: 10,
          total: 10,
          correct_answers: [
            'debug1',
            'debug2',
            'debug3',
            'debug4',
            'debug5',
            'debug6',
            'debug7',
            'debug8',
            'debug9',
            'debug10',
          ],
          incorrect_answers: {},
        },
        fail: {
          correct: 0,
          total: 10,
          correct_answers: [],
          incorrect_answers: {
            debug1: 'wrong 1',
            debug2: 'wrong 2',
            debug3: 'wrong 3',
            debug4: 'wrong 4',
            debug5: 'wrong 5',
            debug6: 'wrong 6',
            debug7: 'wrong 7',
            debug8: 'wrong 8',
            debug9: 'wrong 9',
            debug10: 'wrong 10',
          },
        },
      },
    },
  })
  async completeQuiz({ results }: { results: QuizResults }) {
    await this.saveQuizResult(results)
    this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'storylands/lesson/activity': StorylandsLessonActivityController
  }
}
