import { Howl } from 'howler'
import Service, { service } from '@ember/service'
import { isTesting, macroCondition } from '@embroider/macros'
import type { Log } from '@blakeelearning/log'

declare global {
  interface AudioContext {
    // Safari has an additional 'interrupted' state
    state: AudioContextState | 'interrupted'
  }
}

export default class SoundsService extends Service {
  @service
  declare log: Log

  /**
   * Arrow syntax used in order to bind `this`
   */
  onplayerror = (_id: number, error: unknown) => {
    this.log.error(error as Error)
  }

  /**
   * Cache of sounds by name
   */
  sounds = new Map([
    [
      'snap',
      new Howl({
        src: '/assets/audio/snap.mp3',
        onplayerror: this.onplayerror,
      }),
    ],
    [
      'chaching',
      new Howl({
        src: '/assets/audio/chaching.mp3',
        onplayerror: this.onplayerror,
      }),
    ],
  ])

  /**
   * Plays a sound when found in the cache
   * @param name - id name of the sound
   */
  play(name: string) {
    const sound = this.sounds.get(name)
    if (macroCondition(isTesting())) {
      // Do not play sounds in test environment
    } else if (sound) {
      try {
        sound.play()
      } catch (error: unknown) {
        this.log.error(error as Error)
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    sounds: SoundsService
  }
}
