import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"{{style-namespace 'activity-header'}}\">\n  {{#if @showBackButton}}\n    <input\n      class=\"back-arrow-button\"\n      type=\"button\"\n      aria-label=\"Back Button\"\n      {{on \"click\" this.goBack}}\n    />\n  {{/if}}\n\n  {{#unless @hideHomeButton}}\n    {{#if @useHomeButtonAsReturn}}\n      {{! template-lint-disable no-invalid-link-text }}\n      <a\n        href=\"/\"\n        onclick={{this.goBack}}\n        type=\"button\"\n        class=\"back-to-index\"\n      ></a>\n    {{else}}\n      <LinkTo @route={{this.route}} class=\"back-to-index\" data-test-home=\"true\">\n        <span class=\"sr-only\">Home</span>\n      </LinkTo>\n    {{/if}}\n  {{/unless}}\n</div>", {"contents":"<div class=\"{{style-namespace 'activity-header'}}\">\n  {{#if @showBackButton}}\n    <input\n      class=\"back-arrow-button\"\n      type=\"button\"\n      aria-label=\"Back Button\"\n      {{on \"click\" this.goBack}}\n    />\n  {{/if}}\n\n  {{#unless @hideHomeButton}}\n    {{#if @useHomeButtonAsReturn}}\n      {{! template-lint-disable no-invalid-link-text }}\n      <a\n        href=\"/\"\n        onclick={{this.goBack}}\n        type=\"button\"\n        class=\"back-to-index\"\n      ></a>\n    {{else}}\n      <LinkTo @route={{this.route}} class=\"back-to-index\" data-test-home=\"true\">\n        <span class=\"sr-only\">Home</span>\n      </LinkTo>\n    {{/if}}\n  {{/unless}}\n</div>","moduleName":"re-client/components/activity-header.hbs","parseOptions":{"srcName":"re-client/components/activity-header.hbs"}});
import { action } from '@ember/object'
import Component from '@glimmer/component'

/*
* Activity header component is used to display the logo and
* back button at the top of every page. The common and default
* back path is the index pages but in some cases it is overridden
 by passing in an alternative destination ie. 'critters'
*
*/
export default class ActivityHeader extends Component {
  get route() {
    return this.args.destination || 'index'
  }

  @action
  goBack(event) {
    event.preventDefault()
    window.history.back()
  }
}
