import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import { graphql } from 're-client/graphql'
import type LocationTrackerService from 're-client/services/location-tracker'
import type ApolloService from 're-client/services/apollo'
import type { FeatureService } from '@blakeelearning/features'
import ky from 'ky'
import type { RecommendedBooksQuery } from 're-client/graphql/graphql'

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export const RecommendedBooksQueryDocument = graphql(/* GraphQL */ `
  query RecommendedBooks {
    student {
      id
      recommendedBooks {
        code
        coverUrl
      }
      readBooks {
        totalCount
      }
    }
  }
`)

type RecommendedBook = Omit<
  NonNullable<RecommendedBooksQuery['student']>['recommendedBooks'][number],
  '__typename'
>

export default class MyProgramRoute extends Route {
  @service
  declare apollo: ApolloService

  @service
  declare features: FeatureService

  @service
  declare locationTracker: LocationTrackerService

  override async model() {
    let readBooksCount: number
    let recommendedBooks: RecommendedBook[]

    if (this.features.isEnabled('fable_books')) {
      const { data } = await this.apollo.query({
        query: RecommendedBooksQueryDocument,
        fetchPolicy: 'network-only',
      })

      readBooksCount = data.student?.readBooks.totalCount ?? 0
      recommendedBooks = data.student?.recommendedBooks ?? []
    } else {
      const response = await ky
        .get('books', {
          credentials: 'include',
          prefixUrl,
        })
        .json<{
          recommended_books: { id: string; cover_url: string }[]
          books_read: number
        }>()

      readBooksCount = response.books_read
      recommendedBooks = response.recommended_books.map(
        ({ id: code, cover_url: coverUrl }) => ({ code, coverUrl }),
      )
    }

    return {
      books: {
        readBooksCount,
        recommendedBooks,
      },
    }
  }

  override afterModel() {
    this.locationTracker.setCurrentRoute('my-program', [])
  }
}
