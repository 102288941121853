import myProgramBooks from './my-program-books'
import type { Book } from 're-client/mocks/db'

export default function books() {
  return myProgramBooks.recommended_books.map(
    ({ code, cover_url: coverUrl }, i) => ({
      id: `${code}-${i.toString()}`,
      code,
      coverUrl,
      content: {
        code,
        title: `Fake Book ${i.toString()}`,
        covers: {
          front: {
            imageURL: coverUrl,
          },
        },
        pages: [
          {
            imageURL:
              'https://books.static.readingeggs.com/covers/au/funny_photo/funny_photo_vegetables_in_a_van/preview_front_funny_photo_vegetables_in_a_van.jpg',
          },
        ],
        sections: [
          {
            type: 'CHAPTER',
            quizQuestions: [
              {
                text: 'what is the meaning of life?',
                answers: [
                  {
                    isCorrect: false,
                    text: '41',
                  },
                  {
                    isCorrect: true,
                    text: '42',
                  },
                  {
                    isCorrect: false,
                    text: '43',
                  },
                  {
                    isCorrect: false,
                    text: '44',
                  },
                ],
              },
            ],
          },
        ],
      },
    }),
  ) satisfies Partial<Book>[]
}
