import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import { underscore } from '@ember/string'
import type StudentProgressService from 're-client/services/student-progress'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type AssignmentsService from 're-client/services/assignments'
import type DrivingTestsQuizRoute from 're-client/routes/driving-tests/quiz'
import type { ModelFor } from 're-client/utils/route-model'
import type LogoutService from 're-client/services/logout'
import { graphql } from 're-client/graphql'
import type ErrorHandlerService from 're-client/services/error-handler'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'

interface DrivingTestResults {
  quiz: number
  test_type: string
  correct_answers: string[]
  incorrect_answers: Record<string, string>
  passed: boolean
}

export const saveDrivingTestQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveDrivingTestQuizResult(
    $input: DrivingTestQuizResultCreateInput!
  ) {
    drivingTestQuizResultCreate(input: $input) {
      student {
        id
        ...StudentProgressFragment
        ...AssignmentTask
      }
    }
  }
`)

export default class DrivingTestsQuizController extends Controller {
  declare model: ModelFor<DrivingTestsQuizRoute>

  @service declare studentProgress: StudentProgressService

  @service declare router: RouterService

  @service declare user: UserService

  @service declare assignments: AssignmentsService

  @service declare logout: LogoutService

  @service declare errorHandler: ErrorHandlerService

  saveDrivingTestQuizResultMutation = useMutation(
    this,
    saveDrivingTestQuizResultMutationDocument,
  )

  get isAssignmentMode() {
    return this.model?.isAssignmentMode
  }

  get shouldLogout() {
    return (
      this.user.student.rosterEnabled &&
      this.isAssignmentMode &&
      !this.assignments.currentTask
    )
  }

  @action
  goToLobby() {
    if (this.shouldLogout) {
      this.logout.doLogout()
      return
    }
    void this.router.transitionTo('driving-tests')
  }

  @action
  startRace() {
    void this.router.transitionTo('driving-tests.racing-game', {
      queryParams: {
        assignmentMode: this.isAssignmentMode,
      },
    })
  }

  @action
  async completeDrivingTest(results: DrivingTestResults, uuid?: string) {
    this.user.setRacingGameAvailability(results.passed)
    const category = underscore(results.test_type)

    let assignment = null
    try {
      if (
        this.assignments.currentTask?.__typename ===
          'AssignmentTaskDrivingTests' &&
        this.assignments.canCompleteDrivingTestAssignmentTask(
          results.quiz,
          category,
        )
      ) {
        assignment = {
          assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
          assignmentUuid: this.assignments.currentTask.assignmentUuid,
          gradePosition: this.assignments.currentTask.gradePosition,
        }
      }

      const data = await this.saveDrivingTestQuizResultMutation.current.mutate({
        variables: {
          input: {
            correctAnswers: results.correct_answers,
            incorrectAnswers: results.incorrect_answers,
            category,
            quizPosition: results.quiz,
            attemptUuid: uuid ?? uuidV4(),
            assignment,
          },
        },
      })

      if (!data) {
        throw new Error('[SaveDrivingTestQuizResult] mutation returned no data')
      }
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveDrivingTestQuizResult] mutation failed',
        error,
      )
    }
    return
  }
}

declare module '@ember/controller' {
  interface Registry {
    'driving-tests/quiz': DrivingTestsQuizController
  }
}
