import { times } from 'lodash'

export default {
  recommended_books: times(10, (i) => [
    {
      id: `funny_photo_vegetables_in_a_van_${i}`,
      code: `funny_photo_vegetables_in_a_van_${i}`,
      cover_url:
        'https://books.static.readingeggs.com/covers/au/funny_photo/funny_photo_vegetables_in_a_van/preview_front_funny_photo_vegetables_in_a_van.jpg',
      accent: 'au',
    },
    {
      id: `reading_eggs_three_seeds_${i}`,
      code: `reading_eggs_three_seeds_${i}`,
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_three_seeds/preview_front_reading_eggs_three_seeds.jpg',
      accent: 'au',
    },
    {
      id: `reading_eggs_vans_${i}`,
      code: `reading_eggs_vans_${i}`,
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_vans/preview_front_reading_eggs_vans.jpg',
      accent: 'au',
    },
    {
      id: `reading_eggs_i_like_vegetables_${i}`,
      code: `reading_eggs_i_like_vegetables_${i}`,
      cover_url:
        'https://books.static.readingeggs.com/covers/au/reading_eggs/reading_eggs_i_like_vegetables/preview_front_reading_eggs_i_like_vegetables.jpg',
      accent: 'au',
    },
  ]).flat(),
  books_read: 18,
}
