import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type { ModelFor } from 're-client/utils/route-model'
import type SpellingMapQuizRoute from 're-client/routes/spelling/map/quiz'
import { debugAction } from 're-client/utils/debug'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'
import type ErrorHandlerService from 're-client/services/error-handler'

interface MapQuizResults {
  correctCount: number
  totalCount: number
  correctAnswers: string[]
  incorrectAnswers: Record<string, string>
}

export const saveSpellingMapQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveSpellingMapQuizResult(
    $input: SpellingMapQuizResultCreateInput!
  ) {
    spellingMapQuizResultCreate(input: $input) {
      student {
        id
        ...StudentProgressFragment
        ...AssignmentTask
      }
    }
  }
`)

/**
 * End-of-map quiz activity, not to be confused with the end-of-spelling-lesson
 * quiz activity
 */
export default class SpellingMapQuizController extends Controller {
  @service declare studentProgress: StudentProgressService

  @service declare user: UserService

  @service declare router: RouterService

  @service declare errorHandler: ErrorHandlerService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  declare model: ModelFor<SpellingMapQuizRoute>

  saveSpellingMapQuizResultMutation = useMutation(
    this,
    saveSpellingMapQuizResultMutationDocument,
  )

  get currentMap() {
    return this.model.mapId
  }

  // Saves the quiz results as a map quiz complete event
  @action
  async quizCompleted(results: MapQuizResults, uuid?: string) {
    try {
      const data = await this.saveSpellingMapQuizResultMutation.current.mutate({
        variables: {
          input: {
            attemptUuid: uuid ?? uuidV4(),
            correctAnswers: results.correctAnswers,
            incorrectAnswers: results.incorrectAnswers,
            quiz: this.currentMap,
          },
        },
      })
      if (!data) {
        throw new Error(
          '[SaveSpellingMapQuizResult] no data returned from mutation',
        )
      }
      this.interactive.callInteractionMethod('nextable', {
        reward: { eggs: 0 },
      })
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveSpellingMapQuizResult] mutation failed',
        error,
      )
    }
  }

  @action
  @debugAction()
  async next() {
    await this.studentProgress.fetchProgress()

    if (
      this.studentProgress.spellingCurrentMap >
      config.studentProgress.progress.spelling.lastMap
    ) {
      void this.router.transitionTo('spelling.finished-re-spelling')
    } else {
      void this.router.transitionTo('spelling.map', this.model.nextMapId)
    }
  }

  /**
   * Adds one to the student's eggs count
   */
  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    let amount

    if (typeof args === 'number') {
      amount = args
    } else {
      amount = args.amount
    }

    this.user.incrementEggs(amount)
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: 'Pass Quiz', value: 'pass' },
        { label: 'Fail Quiz', value: 'fail' },
      ],
      values: {
        pass: {
          correctCount: 10,
          totalCount: 10,
          correctAnswers: [
            'debug_spelling_map_quiz_1',
            'debug_spelling_map_quiz_2',
            'debug_spelling_map_quiz_3',
            'debug_spelling_map_quiz_4',
            'debug_spelling_map_quiz_5',
            'debug_spelling_map_quiz_6',
            'debug_spelling_map_quiz_7',
            'debug_spelling_map_quiz_8',
            'debug_spelling_map_quiz_9',
            'debug_spelling_map_quiz_10',
          ],
          incorrectAnswers: {},
        },
        fail: {
          correctCount: 0,
          totalCount: 10,
          correctAnswers: [],
          incorrectAnswers: {
            debug_spelling_map_quiz_1: 'wrong_1',
            debug_spelling_map_quiz_2: 'wrong_2',
            debug_spelling_map_quiz_3: 'wrong_3',
            debug_spelling_map_quiz_4: 'wrong_4',
            debug_spelling_map_quiz_5: 'wrong_5',
            debug_spelling_map_quiz_6: 'wrong_6',
            debug_spelling_map_quiz_7: 'wrong_7',
            debug_spelling_map_quiz_8: 'wrong_8',
            debug_spelling_map_quiz_9: 'wrong_9',
            debug_spelling_map_quiz_10: 'wrong_10',
          },
        },
      },
    },
  })
  async completeQuiz({ results }: { results: MapQuizResults }) {
    await this.quizCompleted(results)
    await this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'spelling/map/quiz': SpellingMapQuizController
  }
}
