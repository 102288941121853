import { http, HttpResponse, passthrough } from 'msw'
import config from 're-client/config/environment'
import { db, type PuzzleScore } from './db'
import avatarContent from './fixtures/avatar-content'
import myHouse from './fixtures/my-house'
import myProgramBooks from './fixtures/my-program-books'
import spellingProgress from './fixtures/spelling-progress'
import spellingResults from './fixtures/spelling-results'
import storyFactoryEntriesVoting from './fixtures/story-factory-entries-voting'
import storyFactoryEntriesPrevious from './fixtures/story-factory-entries-previous'

const token = [
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
  'eyJzdWIiOiJhYmMiLCJhdWQiOiJkZWYiLCJleHAiOjE3MDk4NTQ0MDMsImlhdCI6MTcwOTg1MDgwMywiaXNzIjoiaHR0cHM6Ly9zc28uYmxha2Utc3RhZ2luZy5jb20vIiwicm9sZSI6IlN0dWRlbnQiLCJpZCI6MSwiZGF0YSI6eyJpZCI6MSwidHlwZSI6InN0dWRlbnQifX0',
  'P8coh8qzEToMsAGSefFCBL9aIbRqha4zvHOiV0b3M1c',
].join('.')

const gameIds = [
  'arcade_where_s_my_reading_egg',
  'arcade_concentration',
  'arcade_jaz_s_never_ending_jigsaw',
  'arcade_zee_wee_shapes',
  'arcade_marshmallow',
  'arcade_castle_critters',
  'arcade_sam_ant_stamp',
  'arcade_bee_bee_balls',
  'arcade_bluebird_s_puttputt',
  'arcade_wheely_whale_racer',
  'arcade_alphabeetle_s_letter_drop',
  'arcade_reggie_s_eggspress',
  'arcade_thistle_s_snow_spell',
  'arcade_egg_heads',
  'arcade_illy_s_butterfly_adventure',
  'arcade_sid_s_triangle_puzzle',
  'arcade_critter_hunt',
  'arcade_chicken_run',
  'arcade_word_worm',
  'arcade_skittles',
  'arcade_driving_game',
  'arcade_bubble_boy',
  'arcade_stacks_of_blocks',
  'arcade_get_dressed',
]

export const handlers = [
  http.get('https://avatars.static.readingeggs.com/*', () => passthrough()),
  http.get('https://placebear.com/**', () => passthrough()),
  http.get('https://use.typekit.net/**', () => passthrough()),
  http.get('https://p.typekit.net/**', () => passthrough()),
  http.post('/write-coverage', () => passthrough()),
  http.get('/assets/*', () => passthrough()),
  http.post('/api/v1/time_on_task', () => {
    return HttpResponse.text(null, { status: 204 })
  }),
  http.get('/api/v1/avatar/avatar_content', () => {
    return HttpResponse.json(avatarContent)
  }),
  http.get('/api/v1/books', () => {
    return HttpResponse.json(myProgramBooks)
  }),
  http.get('/api/v1/bookshelves/bookshelf', () => {
    return HttpResponse.json({ bookshelf: { id: 'bookshelf' } })
  }),
  http.get('/api/v1/items', () => {
    return HttpResponse.json(db.getStudentItems())
  }),
  http.post<never, { code: string; studentId: string }>(
    '/api/v1/items',
    async function ({ request }) {
      const { code, studentId } = await request.json()
      return HttpResponse.json({ item: { id: 641, code, studentId } })
    },
  ),

  http.get('/api/v1/bundle_items', function ({ request }) {
    const items = new URL(request.url).searchParams.getAll('items[]')
    return HttpResponse.json({
      items: items.map((code, i) => ({ id: 300 + i, code })),
    })
  }),

  http.post('/api/v1/bundle_items', function ({ request }) {
    return new HttpResponse(request.body)
  }),

  http.get('/auth/session', () => {
    return HttpResponse.json({
      data: {
        user_id: 1,
        token,
      },
    })
  }),

  http.get('/api/v1/arcade_game_scores', () =>
    HttpResponse.json({
      content: {
        variables: {
          high_scores: Object.fromEntries(
            gameIds.map((gameId) => [
              gameId,
              {
                name: 'Ebony C.',
                score: 931600,
              },
            ]),
          ),
        },
      },
    }),
  ),

  http.post('/api/v1/arcade_games/:gameId/purchase', () =>
    HttpResponse.json({
      purchased: true,
    }),
  ),

  http.get('/api/v1/arcade_game_scores/personal_bests', () =>
    HttpResponse.json({
      content: {
        variables: {
          personal_bests: Object.fromEntries(
            gameIds.map((gameId, i) => [gameId, i]),
          ),
        },
      },
    }),
  ),

  http.get('/api/v1/puzzle_scores', () => {
    return HttpResponse.json({ puzzle_scores: db.getPuzzleScores() })
  }),
  http.post<never, { puzzle_score: PuzzleScore }>(
    '/api/v1/puzzle_scores',
    async ({ request }) => {
      const { puzzle_score } = await request.json()
      return HttpResponse.json({
        puzzle_score: db.createPuzzleScore(puzzle_score),
      })
    },
  ),

  http.get('/api/v1/spelling_progress/:id', () => {
    return HttpResponse.json(spellingProgress)
  }),

  http.get('/api/v1/spelling_results/:id', () => {
    return HttpResponse.json(spellingResults)
  }),

  http.get(`/data/:accent/reading/activities/my_house.json`, () => {
    return HttpResponse.json(myHouse)
  }),

  ...lessonsHandlers(),
  ...contentLoaderHandlers(),
  ...storyFactoryHandlers(),
]

function* contentLoaderHandlers() {
  for (const host of Object.values(config.contentLoader.caper.assetHosts)) {
    yield http.get(`${String(host)}/**`, () => passthrough())
  }

  for (const host of Object.values(config.contentLoader.jester.assetHosts)) {
    yield http.get(`${String(host)}/**`, () => passthrough())
  }
}

function storyFactoryHandlers(namespace = '/api/v1') {
  return [
    http.get(`${namespace}/story_factory_contests/current`, () => {
      return HttpResponse.json({
        content: {
          variables: {
            contest: {},
          },
        },
      })
    }),

    http.get(`${namespace}/story_factory_contests/previous`, () => {
      return HttpResponse.json(storyFactoryEntriesPrevious)
    }),

    http.get(`${namespace}/story_factory_contests/voting`, () => {
      return HttpResponse.json(storyFactoryEntriesVoting)
    }),

    http.get(`${namespace}/story_factory_entries`, () => {
      return HttpResponse.json({
        content: {
          variables: {
            entries: [],
          },
        },
      })
    }),

    http.get(`${namespace}/story_factory_entries/:id`, () => {
      return HttpResponse.json({
        content: {
          variables: {},
        },
      })
    }),

    http.post(`${namespace}/story_factory_entries/:id`, () => {
      return HttpResponse.json({
        content: {
          variables: {},
        },
      })
    }),

    http.put(`${namespace}/story_factory_entries/:id`, () => {
      return HttpResponse.json({
        content: {
          variables: {},
        },
      })
    }),
  ]
}

function lessonsHandlers() {
  const path = new URL(
    'readingeggs/releases/:env/data/:accent/:precinct/lessons/:id.json',
    config.contentLoader.caper.assetHosts.code,
  ).toString()

  return [
    http.get<{ precinct: string; id: string }>(path, ({ params }) => {
      if (config.environment === 'test') {
        const { id, precinct } = params
        return HttpResponse.json(db.getMapLesson(precinct, id))
      }

      return passthrough()
    }),
  ]
}
